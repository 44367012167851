<template lang="">
    <div>
      <h6 class="my-4 mx-5">Mülk Özellikleri</h6>
      <div class="d-flex align-items-center justify-content-between search-list-area">
        <div class="d-flex align-items-center justify-content-between search-input mb-4">
          <div class="col-md-2 col-12">
            <select v-model="list_length" class="form-control ml-2">
              <option value="5" selected>Seçiniz</option>
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
            </select> 
          </div>
          <input placeholder="Ara.." v-model="search" class="form-control mr-2" />
          <div class="d-flex justify-content-end">
            <b-button
              variant="outline-success"
              class="d-flex d-md-inline-block ml-2 w-md-auto w-100"
              @click="addModal = true"
              >Özellik Ekle</b-button
            >
            <b-button
              variant="outline-primary"
              class="d-flex d-md-inline-block ml-2 w-md-auto w-100"
              v-b-toggle.sidebar-right
              >Filtrele
            </b-button>
          </div>
        </div>
      </div>
  
      <div class="custom-list">
        <List
          :header="header"
          :data="items"
          :pagination="pagination"
          :loading="loading"
          @updatePage="updatePage"
          moduleName="Mülk Özellikleri Listesi"
          :moduleCreateEvent="true"
          :moduleSearch="search"
        >
         
  
          <template v-slot:item.action="{ item }" class="p-0">
            <div class="d-flex justify-content-end">
                <button class="btn" :href="href" @click="editItem(item)">
                  <i class="flaticon2-arrow text-dark p-0"></i>
                </button>
              </router-link>
                <button class="btn" :href="href"  @click="openDelete()">
                  <i class="flaticon2-trash text-danger p-0"></i>
                </button>
            </div>
          </template>
        </List>
        <!-- Create and Actions Popups -->
        <div class="notes-sidebar top-full">
          <b-sidebar id="sidebar-right" right>
            <div
              class="card dveb-gray-card aside-gray-card tab-card"
              style="overflow-y: auto"
            >
              <div class="card-header">FİLTRELE</div>
              <b-tabs content-class="mt-3">
                <b-tab content-class="tab-content" title="Filtre" active>
                  <div class="form-group">
                    <label for="sahip">Sahibi</label>
                    <select name="" id="sahip" class="form-control">
                      <option value="" selected>Seçiniz</option>
                      <option value="">Şekerbank A.Ş</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="izle">İzlenecek</label>
                    <select name="" id="izle" class="form-control">
                      <option value="" selected>Seçiniz</option>
                      <option value="">Evet</option>
                      <option value="">Hayır</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="durum">Durum</label>
                    <select name="" id="durum" class="form-control">
                      <option value="" selected>Seçiniz</option>
                      <option value="">1</option>
                      <option value="">2</option>
                      <option value="">3</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="il">İl</label>
                    <select name="" id="il" class="form-control">
                      <option value="" selected>Seçiniz</option>
                      <option value="">1</option>
                      <option value="">2</option>
                      <option value="">3</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="ilce">İlçe</label>
                    <select name="" id="ilce" class="form-control">
                      <option value="" selected>Seçiniz</option>
                      <option value="">1</option>
                      <option value="">2</option>
                      <option value="">3</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="anahat">Anahtar Durumu</label>
                    <select name="" id="anahat" class="form-control">
                      <option value="" selected>Seçiniz</option>
                      <option value="">Var</option>
                      <option value="">Yok</option>
                      <option value="">3</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="anahat" class="form-label">İşgalci Durumu</label>
                    <select name="" id="anahat" class="form-control">
                      <option value="" selected>Seçiniz</option>
                      <option value="">Var</option>
                      <option value="">Yok</option>
                      <option value="">3</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="anahat">Ada</label>
                    <input type="text" class="form-control" />
                  </div>
                  <div class="form-group">
                    <label for="anahat">Parsel</label>
                    <input type="text" class="form-control" />
                  </div>
                  <div class="row">
                    <div class="col-md-6 col-12 mt-4">
                      <b-button variant="light" class="w-100"> Temizle </b-button>
                    </div>
                    <div class="col-md-6 col-12 mt-4">
                      <b-button variant="light" class="w-100"> Uygula </b-button>
                    </div>
                    <div class="col-12 mt-4">
                      <b-button variant="light" class="w-100">
                        Bu Filtreyi Kaydet
                      </b-button>
                    </div>
                  </div>
                </b-tab>
                <b-tab title="Hızlı Ara">
                  <div
                    class="card mt-4 dveb-card-white"
                    style="border-radius: 0 !important"
                  >
                    <div>
                      <h4>Filtre Örnek</h4>
                      <hr />
                      <div class="row ml-auto">
                        <b-button variant="success" class="mr-4">
                          Uygula
                        </b-button>
                        <b-button
                          variant="danger"
                          class=""
                          @click="openDeleteHandler(key)"
                        >
                          Sil
                        </b-button>
                      </div>
                    </div>
                  </div>
                </b-tab>
              </b-tabs>
            </div>
          </b-sidebar>
        </div>
        <b-modal v-model="addModal" id="add-1" title="Yeni Özellik Ekle">
          <div class="form-group">
            <MultiSelect :multiple="false" :options="selectOptions" label="Genel Özellikler" />
          </div>
          <div class="form-group">
            <label for="deger">Değer</label>
            <input type="text" class="form-control" placeholder="Değer Giriniz...">
          </div>
          <template #modal-footer>
              <button class="btn btn-secondary" @click="addModal = false">Çık</button>
              <button class="btn btn-success" @click="addModal = false">Kaydet</button>
          </template>
        </b-modal>
        <b-modal v-model="openEditItemModal" id="add-1" title="Yeni Özellik Ekle">
          <div class="form-group">
            <label for="deger">Mülk Tipi</label>
            <input type="text" :value="this.selectedItem.tip" class="form-control" placeholder="Değer Giriniz...">
          </div>
          <div class="form-group">
            <label for="deger">Genel Özellik</label>
            <input type="text" :value="this.selectedItem.ozellik" class="form-control" placeholder="Değer Giriniz...">
          </div>
          <template #modal-footer>
              <button class="btn btn-secondary" @click="openEditItemModal = false">Çık</button>
              <button class="btn btn-success" @click="openEditItemModal = false">Kaydet</button>
          </template>
        </b-modal>
        <DeleteOperation modalid="deleteSetting2"/>

      </div>
    </div>
  </template>
  <script>
  export default {
    name: "mulk-ozellikleri",
    data() {
      return {
        search: "",
        filters: "",
        showInsertUpdate: false,
        showDetail: false,
        showImportModal: false,
        resetForm: false,
        confirmCreate: false,
        loading: false,
        addModal: false,
        badgesEnum: ["green", "yellow", "red"],
        stepNames: ["Hazırlık", "Satış", "Devir"],
        header: [
          { text: "id", sortable: true, value: "id", size: "60px" },
          { text: "Mülk Tipi", sortable: true, value: "tip" },
          { text: "Genel Özellik", sortable: true, value: "ozellik" },
          { text: "İşlemler", value: "action", size: "100px" },
        ],
        selectOptions: [
          { text: "Oda Sayısı", value: "1" },
          { text: "Banyo Sayısı", value: "2" },
          { text: "Metre Kare", value: "3" },
          { text: "Boya", value: "4" },
        ],
        items: [],
        pagination: {},
        meta: {},
        listSelectedItemIds: [],
        selectedItem: "",
        deleteOperationUrl: "",
        deleteIds: "",
        filterList: [],
        list_length: 5,
        openFullTextModal: false,
        openEditItemModal:false,
        selectedItem :{}
      };
    },
    components: {
      Moretext: () => import("@/components/global/Moretext"),
    },
    created() {
      this.search = this.$route.query.search;
      this.search = this.search.toLowerCase();
      this.getList();
    },
    methods: {
      getList() {
        this.loading = true;
        this.items = [
          {
            id: 1,
            tip: "Apartman",
            ozellik: "Oda Sayısı",
          },
          {
            id: 2,
            tip: "Apartman",
            ozellik: "Banyo Sayısı",
          },
          {
            id: 3,
            tip: "Arsa",
            ozellik: "Metre Kare",
          },
          {
            id: 4,
            tip: "Arsa",
            ozellik: "Besi",
          },
        ];
        this.totalItems = this.items.length;
  
        this.pagination = {
          current_page: 1,
          total_pages: Math.ceil(this.items?.length / this.list_length),
          total_items: this.items.length,
          list_length: this.list_length,
        };
        this.loading = false;
        if (this.search) {
          const searchValue = this.search;
          const foundItems = this.items.filter((item) => {
            for (const prop in item) {
              if (item[prop].toString().toLowerCase().includes(searchValue)) {
                return true;
              }
            }
            return false;
          });
          this.items = foundItems;
        }
  
        // this.items = [];
        // this.totalItems = 0;
  
        // let queryLimit = this.perPage ? this.perPage : 10;
        // let query = `user/list?Page=${
        //   this.page ? this.page : 1
        // }&limit=${queryLimit}`;
        // if (this.search) {
        //   query += `&search=${this.search}`;
        // }
  
        // this.$dbFunctions
        //   .get(query)
        //   .then((res) => {
        //     if (res.success == true && res.count > 0) {
        //       this.items = res.data;
        //       this.totalItems = res.count;
  
        //       this.pagination = {
        //         current_page: res.page,
        //         total_pages: res.pageCount,
        //         total_items: res.count,
        //       };
        //     }
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //   })
        //   .finally(() => {
        //     this.loading = false;
        //   });
      },
      updatePage(value) {
        this.meta = value;
  
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      },
      openDeleteHandler(key) {
        this.$bvModal.show("modal-deleteOperation");
        let index = this.notes.indexOf(key);
        this.notes.splice(index, 1);
      },
      openDelete() {
      this.$bvModal.show("modal-deleteOperationdeleteSetting2");
    },
    editItem(item){
        this.openEditItemModal = true
        this.selectedItem = item
    }
    

    },
    watch: {
      meta: {
        handler() {
          this.getList();
        },
        deep: true,
      },
      search: function () {
        this.getList();
      },
      list_length: function () {
        this.getList();
      },
      $route: function () {
        this.search = this.$route.query.search;
        this.search = this.search.toLowerCase();
      },
    },
  };
  </script>
  <style lang=""></style>
  